<template>
  <div class="images-component">
    <h3>
      {{ $t("showCourse.resources.imagesHead") }}
      <b-spinner small v-if="isLoading" />
      <button v-else @click="$refs.ImportImageInput.$el.click()">
        <img :src="addIcon" alt="add-icon" />
      </button>
    </h3>
    <div class="images row px-1">
      <a
        v-for="image in imagesData"
        :href="image.path"
        :key="image.id"
        target="_blank"
        class="img"
      >
        <img :src="image.path" alt="image" />
        <button @click.prevent="deleteImage(image.id)" class="delete-ico">
          <feather-icon icon="XIcon" />
        </button>
      </a>
    </div>
    <b-form-file
      plain
      ref="ImportImageInput"
      accept="image/jpeg"
      hidden
      @change="handelNewImage"
    />
  </div>
</template>
<script>
import { BFormFile, BSpinner } from "bootstrap-vue";

export default {
  name: "ImagesComponent",
  components: { BFormFile, BSpinner },
  props: {
    imagesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/uploadFile.svg"),
      isLoading: false,
    };
  },
  methods: {
    async deleteImage(_id) {
      try {
        this.isLoading = true;

        const request = await this.$http.delete(`teacher/resources/${_id}`);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelNewImage(event) {
      try {
        this.isLoading = true;
        const body = this.prepareData(event);
        const request = await this.$http.post("teacher/resources", body);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (err) {
        console.log(err);
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    prepareData(event) {
      const { files } = event.target;
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`media[${i}]`, file);
        formData.append("description", file.name);
      });
      formData.append("course_id", this.$route.params.id);
      formData.append("type", "media");
      return formData;
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
