var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.classroom.show_course/boards/styles'),"label-for":"styles","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"style","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"styles-container"},[_c('div',{staticClass:"styles-content"},[_c('b-row',{class:{ 'd-none': _vm.is_collapse_visible }},[_c('b-col',{staticClass:"available-styles",attrs:{"cols":"12"}},[_c('span',{staticClass:"styles-color-cube",style:({
                backgroundColor: '#F5F5F5',
                backgroundImage: ("url(" + _vm.noStyleIcon + ")"),
                marginLeft: '8px',
              }),attrs:{"autocomplete":"false"},on:{"click":function($event){return _vm.selectStyle(null)}},model:{value:(_vm.selectedStyle),callback:function ($$v) {_vm.selectedStyle=$$v},expression:"selectedStyle"}}),_vm._l((_vm.styles),function(style){return _c('span',{key:style.id,staticClass:"styles-color-cube",style:({
                backgroundColor: style.primary_color,
                opacity: _vm.selectedStyle !== style.id ? '0.5' : '1',
                border: '1px solid #2776B6',
                boxShadow: '0 2px 9px 1px rgba(0, 117, 211, 0.25)',
              }),attrs:{"id":("style_" + (style.id)),"autocomplete":"false"},on:{"click":function($event){return _vm.selectStyle(style.id)}}})})],2)],1),_c('b-collapse',{staticStyle:{"margin-left":"4px"},attrs:{"id":"show-all-styles"}},[_c('span',{staticClass:"styles-color-cube",style:({
              backgroundColor: '#F5F5F5',
              backgroundImage: ("url(" + _vm.noStyleIcon + ")"),
              marginLeft: '4px',
            }),attrs:{"autocomplete":"false"},on:{"click":function($event){return _vm.selectStyle(null)}},model:{value:(_vm.selectedStyle),callback:function ($$v) {_vm.selectedStyle=$$v},expression:"selectedStyle"}}),_vm._l((_vm.styles),function(style){return _c('span',{key:style.id,staticClass:"styles-color-cube",style:({
              backgroundColor: style.primary_color,
              opacity: _vm.selectedStyle !== style.id ? '0.5' : '1',
              border: '1px solid #2776B6',
              boxShadow: '0 2px 9px 1px rgba(0, 117, 211, 0.25)',
            }),attrs:{"id":("style_" + (style.id)),"autocomplete":"false"},on:{"click":function($event){return _vm.selectStyle(style.id)}}})})],2),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"show-all-styles-btn",on:{"click":_vm.showAllStyles}},[_vm._v(" "+_vm._s(_vm.is_collapse_visible ? _vm.$t("g.classroom.show_course/boards/hide-all-styles") : _vm.$t("g.classroom.show_course/boards/show-all-styles"))+" "),_c('feather-icon',{attrs:{"icon":_vm.is_collapse_visible ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"12"}})],1)],1)],1)],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }