<template>
  <Loading v-if="isLoading" />
  <div
    class="d-flex flex-column"
    v-else-if="!isLoading && summaryData.length > 0"
  >
    <b-row
      class="d-flex align-self-end justify-content-between mb-1 mr-1 flex-wrap"
    >
      <b-col
        v-for="statusItem in status"
        :key="statusItem.id"
        class="d-flex align-items-center mr-1 mb-1"
        style="gap: 15px"
      >
        <img :src="statusItem.icon" :alt="statusItem.title" />
        <p class="m-0">{{ statusItem.title }}</p>
      </b-col>
    </b-row>
    <table class="summary-table">
      <tr class="table-header">
        <th>{{ $t("g.student") }}</th>
        <th><img :src="warnningIcon" alt="Absent" /></th>
        <th><img :src="corresctIcon" alt="Present" /></th>
        <th><img :src="errorIcon" alt="Came late" /></th>
        <th><img :src="primrayIcon" alt="Left early" /></th>
        <th><img :src="closeIcon" alt="Have excuse" /></th>
      </tr>
      <tr
        class="table-body"
        v-for="(student, index) in summaryData"
        :key="index"
      >
        <td>
          <img
            width="40"
            height="40"
            class="user-avatar"
            alt="student.user.name"
            :src="
              $helpers.filterImages(
                $helpers.uiAvatar(student.user.name),
                'avatar',
                student.user.media
              )
            "
          />
          {{ student.user.name }}
        </td>
        <td>
          {{ student.attendance_summary.absent_count }}
        </td>
        <td>
          {{ student.attendance_summary.present_count }}
        </td>
        <td>
          {{ student.attendance_summary.come_late_count }}
        </td>
        <td>
          {{ student.attendance_summary.come_early_count }}
        </td>
        <td>
          {{ student.attendance_summary.excuses_count }}
        </td>
      </tr>
    </table>
  </div>
  <div class="d-flex justify-content-center w-100 no-data-section" v-else>
    <p>{{ $t("g.no_data_for_table") }}</p>
  </div>
</template>
<script>
import Loading from "@/components/loading/loading.vue";
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    Loading,
    BRow,
    BCol
  },
  data() {
    return {
      isLoading: true,
      summaryData: [],
      status: [
        {
          id: 2,
          icon: require("@/assets/images/icons/done.svg"),
          label: "present",
          title: this.$t("present"),
        },
        {
          id: 1,
          icon: require("@/assets/images/icons/trangel.svg"),
          label: "absence",
          title: this.$t("absence"),
        },
        {
          id: 3,
          icon: require("@/assets/images/icons/late.svg"),
          label: "come late",
          title: this.$t("cameLate"),
        },
        {
          id: 4,
          icon: require("@/assets/images/icons/leavesLate.svg"),
          label: "leave early",
          title: this.$t("leaveEarly"),
        },
        {
          id: 5,
          icon: require("@/assets/images/icons/close2.svg"),
          label: "total excuses",
          title: this.$t("totalExcuses"),
        },
      ],
      errorIcon: require("@/assets/images/icons/late.svg"),
      warnningIcon: require("@/assets/images/icons/trangel.svg"),
      closeIcon: require("@/assets/images/icons/close2.svg"),
      primrayIcon: require("@/assets/images/icons/leavesLate.svg"),
      corresctIcon: require("@/assets/images/icons/done.svg"),
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getSummaryData(this.$route.params.id);
    }
  },
  methods: {
    async getSummaryData(id) {
      try {
        this.isLoading = true;
        const res = await this.$http.get(`teacher/summary/${id}`);
        this.summaryData = res.data.data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
