<template>
  <div class="videos-component">
    <h3>
      {{ $t("showCourse.resources.videosHead") }}
      <b-spinner small v-if="isLoading" />
      <button v-else @click="$refs.ImportVideoInput.$el.click()">
        <img :src="addIcon" alt="add-icon" />
      </button>
    </h3>
    <div class="videos row px-1">
      <a v-for="video in videosData" class="video" :key="video.id">
        <div class="div"></div>
        <button @click="deleteVideo(video.id)" class="delete-ico">
          <feather-icon icon="XIcon" />
        </button>
        <feather-icon
          @click="openVideoPreview(video.path, video.type)"
          icon="PlayCircleIcon"
          class="play-icon"
      /></a>
    </div>
    <b-form-file
      plain
      ref="ImportVideoInput"
      accept="video/*"
      hidden
      @change="handelNewVideo"
    />
    <video-preview-component
      :path="videoPathToPreview"
      :type="videoTypeToPreview"
    />
  </div>
</template>
<script>
import { BFormFile, BSpinner } from "bootstrap-vue";
import VideoPreviewComponent from "@/components/classrooms/resourcesComponent/Modals/VideoPreviewModal/index.vue";

export default {
  name: "VideoComponent",
  components: {
    VideoPreviewComponent,
    BFormFile,
    BSpinner,
  },
  props: {
    videosData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      videoPathToPreview: null,
      videoTypeToPreview: null,
      addIcon: require("@/assets/images/icons/uploadFile.svg"),
      isLoading: false,
      videoIcon: require("@/assets/images/videoIcon.svg"),
    };
  },
  methods: {
    async deleteVideo(_id) {
      try {
        this.isLoading = true;

        const request = await this.$http.delete(`teacher/resources/${_id}`);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelNewVideo(event) {
      try {
        this.isLoading = true;
        const body = this.prepareData(event);
        const request = await this.$http.post("teacher/resources", body);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (err) {
        console.log(err);
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    prepareData(event) {
      const { files } = event.target;
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`media[${i}]`, file);
        formData.append("description", file.name);
      });
      formData.append("course_id", this.$route.params.id);
      formData.append("type", "media");
      return formData;
    },
    openVideoPreview(path, type) {
      this.videoPathToPreview = path;
      this.videoTypeToPreview = type;
      this.$bvModal.show("video-preview-modal");
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
