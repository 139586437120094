<template>
  <div class="card-lesson">
    <div class="d-flex align-items-center h-100" @click="toggleCollapse(index, lesson.id)">
      <b-link :to="{
        name: 'CourseDetails',
        params: { id: course_id, lesson_id: lesson.id },
      }" class="d-flex justify-content-start box-lesson h-100">
        <img v-if="lesson.media" :src="lesson.media[0].path" :alt="lesson.name" class="lesson__image" />
        <img v-else src="@/assets/images/pages/Rectangle 846.png" :alt="lesson.name" class="lesson__image" />
        <div class="lesson__content">
          <h4 class="lesson__week">{{ lesson.name }}</h4>
        </div>
      </b-link>
      <div class="collapse-icon mr-1" role="button" tabindex="0" @click="toggleCollapse()">
        <feather-icon size="20" icon="ChevronDownIcon" color="#000" />
      </div>
    </div>
    <hr class="divider mx-3" v-if="activeCollapse === index" />
    <b-collapse :visible="activeCollapse === index">
      <div class="p-2">
        {{ lesson.description ? lesson.description : $t("g.ThereIsNoDescription") }}
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BLink, BCollapse } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BCollapse,
  },

  props: ["lesson", "course_id", "index"],
  data() {
    return {
      activeCollapse: null,
    };
  },
  methods: {
    toggleCollapse() {
      if (this.activeCollapse === this.index) {
        this.activeCollapse = null;
      } else {
        this.activeCollapse = this.index;
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
