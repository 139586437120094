<template>
  <div class="add-lesson-modal cursor-pointer" @click="$bvModal.show('bv-modal-add-week')">
    <div class="add-circle">
      <img :src="require('@/assets/dev/plus.svg')" alt="">
    </div>
    <h3>{{ $t('g.show_course/weeks/add_week') }}</h3>

    <validation-observer ref="addLessonRef">
      <b-form>
        <b-modal id="bv-modal-add-week" centered :cancel-title-html="$t('g.show_course/weeks/cancel')" :ok-title-html="$t('g.show_course/weeks/send')" @ok="validation">
          <!-- Start Modal Header -->
          <template #modal-header>
            <div class="mb-2 d-flex justify-content-between w-100 align-items-center">
              <b-card-text class="mb-0">
                {{ $t('g.show_course/weeks/add_week') }}
              </b-card-text>
              <img class="cursor-pointer" :src="exitIcon" alt="Hide add week" @click="$bvModal.hide('bv-modal-add-week')">
            </div>
            <span class="sub-title">
              {{ $t('g.please_fill_in_the_required_data') }}
            </span>
          </template>
          <!-- End Modal Header -->

          <!-- Start Course Name Input -->
          <b-form-group label-for="name" class="mb-2" :label="$t('g.show_cours/weeks/week_name')" label-size="lg">
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input :placeholder="$t('g.show_cours/weeks/week_name')" v-model="name" />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <!-- End Course Name Input -->

          <!-- Start Description Textarea -->
          <b-form-group :label="$t('g.show_course/weeks/description')" label-for="description" class="mb-2"
            label-size="lg">
            <validation-provider #default="{ errors }" name="description" rules="required">
              <b-form-textarea :placeholder="$t('g.show_course/weeks/description')" v-model="description" id="description"
                rows="5" max-rows="10" />
              <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- End Description Textarea -->

          <!-- Start Date Select -->
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label-for="start date">
                <validation-provider #default="{ errors }" name="start date" rules="required">
                  <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :placeholder="$t('g.start_date')" v-model="startDate" />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label-for="end date">
                <validation-provider #default="{ errors }" name="end date" rules="required">
                  <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :placeholder="$t('g.end_date')" v-model="endDate" />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="dateError" cols="12" class="date-error">
              <p>{{ $t('g.alert.the_start_date_must_be_before_end_date') }}</p>
            </b-col>
          </b-row>
          <!-- End Date Select -->

          <!-- Start Image Input -->
          <b-form-group label-for="week image">
            <validation-provider #default="{ errors }" name="week image" rules="required">
              <div class="image-input-area">
                <b-form-file class="image-input" ref="courseImage" v-model="selectedimage" accept="image/*"></b-form-file>
                <div class="image-icon">
                  <img :src="imageInputIcon" alt="Add week image">
                  <div class="icon-circle">
                    <img :src="plusIcon" alt="plus icon">
                  </div>
                </div>
                <p class="image-text mb-0">{{ $t('g.upload_photo') }}</p>
              </div>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
            <div class="mt-1 d-flex w-100 align-items-center justify-content-between" v-if="selectedimage">
              <div class="d-flex">
                <img class="mr-1" :src="imageIcon" width="22" height="22" alt="">
                <p class="m-0">{{ selectedimage.name }}</p>
              </div>
              <img @click="editImage" :src="editIcon" alt="">
            </div>
          </b-form-group>
          <!-- End Image Input -->

          <!-- Overlay component -->
          <overlay-component :isLoading="is_loading" />
          <!-- Overlay component -->
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import {
  BCardText,
  BCol,
  BForm,
  BFormDatepicker, BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";

export default {
  name: "AddLessonModal",
  components: {
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    OverlayComponent,
    required,
    BRow,
    BCol,
    BFormDatepicker,
    BModal,
    BForm,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  data() {
    return {
      name: null,
      description: null,
      startDate: null,
      endDate: null,
      dateError: false,
      selectedimage: null,
      is_loading: false,
      exitIcon: require('@/assets/dev/exit_icon.svg'),
      plusIcon: require('@/assets/dev/image_input_icon2.svg'),
      imageInputIcon: require('@/assets/dev/image_input_icon.svg'),
      editIcon: require('@/assets/images/icons/pencil.svg'),
      imageIcon: require('@/assets/images/icons/photo.png'),
    }
  },
  methods: {
    validation(bvModalEvent) {
      bvModalEvent.preventDefault()
      const dateState = this.compareShortDates()
      this.$refs.addLessonRef.validate().then((success) => (success && dateState) && this.createLesson())
    },
    compareShortDates() {
      if (this.startDate && this.endDate) {
        const startDate = new Date(this.startDate)
        const endDate = new Date(this.endDate)
        if (startDate > endDate) {
          this.dateError = true
          return 0
        } else if (startDate < endDate) {
          this.dateError = false
          return 1
        }
      }
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      })
    },
    createLesson() {
      this.is_loading = true
      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('describtion', this.description)
      formData.append('start_date', this.startDate)
      formData.append('end_date', this.endDate)
      formData.append('image', this.selectedimage)
      formData.append('color', 'white')
      formData.append('is_completion_required', 1)
      formData.append('is_locked', 0)
      formData.append('is_visible', 1)

      this.$http.post(`teacher/courses/${this.$route.params.id}/lessons`, formData)
        .then((success) => {
          if (success) {
            this.is_loading = false
            this.title = null
            this.description = null
            this.startDate = null
            this.endDate = null
            this.selectedimage = null
            this.$emit('weekCreated')
            this.$bvModal.hide('bv-modal-add-week')
            this.makeToast('success', 'g.alert.week_created_successfully', 'g.send.successTitle')
            this.$emit('refresh-data')
          }
        })
        .catch((error) => {
          this.is_loading = false
          this.$helpers.handleError(error);
        })
    },
    editImage() {
      this.selectedimage = null
      this.$refs.courseImage.$el.querySelector('input[type="file"]').click()
    },
    showError(error, defaultError) {
      if (error.response?.data?.errors) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } else if (error.response?.data?.message) {
        this.makeToast(
          "danger",
          error.response?.data?.message,
          this.$t("g.send.errorTitle")
        );
      } else {
        this.makeToast(
          "danger",
          this.$t(`g.${defaultError}`),
          this.$t("g.send.errorTitle")
        );
      }
    },
  }
}
</script>

<style lang="scss" src="./_index.scss"/>
