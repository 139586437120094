<template>
  <validation-observer ref="addBoardRef">
    <b-form>
      <b-modal
        centered
        hide-footer
        id="bv-modal-add-board"
        :cancel-title-html="$t('g.show_course/weeks/cancel')"
        :ok-title-html="$t('g.show_course/weeks/send')"
      >
        <!-- Start Modal Header -->
        <template #modal-header>
          <div
            class="mb-2 d-flex justify-content-between w-100 align-items-center"
          >
            <b-card-text class="mb-0">
              {{ $t("g.show_course/boards/add-board") }}
            </b-card-text>
            <img
              :src="exitIcon"
              alt="Hide add course"
              @click="$bvModal.hide('bv-modal-add-board')"
            />
          </div>
          <span class="sub-title">
            {{ $t("g.please_fill_in_the_required_data") }}
          </span>
        </template>
        <!-- End Modal Header -->

        <!-- Start Board Title Input -->
        <b-form-group
          :label="$t('g.classroom.show_course/boards/title')"
          label-for="title"
          class="mb-2"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-input
              :placeholder="$t('g.classroom.show_course/boards/title')"
              v-model="title"
              id="title"
              trim
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Board Title Input -->

        <!-- Start Description Textarea -->
        <b-form-group
          :label="$t('g.classroom.show_course/boards/description')"
          label-for="description"
          class="mb-2"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-textarea
              :placeholder="$t('g.classroom.show_course/boards/description')"
              v-model="description"
              id="description"
              rows="5"
              max-rows="10"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>

        <!-- End Description Textarea -->

        <!-- Start Styles Select -->
        <BoardStyles @selectStyle="selectedStyle = $event" />
        <!-- End Styles Select -->

        <!-- Start Form Actions Buttons -->
        <b-row align-h="end" class="report-footer m-0 mt-5">
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('bv-modal-add-board')"
            >{{ $t("g.cancel") }}</b-button
          >
          <b-button
            :disabled="is_loading"
            variant="primary"
            type="submit"
            @click.prevent="validation"
          >
            {{ $t("g.Send") }}
          </b-button>
        </b-row>
        <!-- Start Form Actions Buttons -->

        <!-- Overlay component -->
        <overlay-component :isLoading="is_loading" />
        <!-- Overlay component -->
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  BCol,
  BForm,
  BFormTextarea,
  BRow,
  BFormGroup,
  BCardText,
  BFormInput,
  BCollapse,
} from "bootstrap-vue";
import BoardStyles from "@/components/classrooms/boards/showCourses/boardStyles/index.vue";

export default {
  name: "AddBoardModal",
  components: {
    BoardStyles,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BCollapse,
    OverlayComponent,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required: required,
      is_loading: true,
      title: null,
      description: null,
      selectedStyle: null,
      exitIcon: require("@/assets/dev/exit_icon.svg"),
      plusIcon: require("@/assets/dev/image_input_icon2.svg"),
    };
  },
  created() {
    this.is_loading = false;
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    validation() {
      this.$refs.addBoardRef
        .validate()
        .then((success) => success && this.createBoard());
    },
    createBoard() {
      this.is_loading = true;
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("board_style_id", this.selectedStyle);
      formData.append("course_id", this.$route.params.id);

      this.$http
        .post(`/teacher/boards`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.is_loading = false;
            this.$bvModal.hide("bv-modal-add-board");
            this.makeToast("success", res.data.message, res.data.message);
            this.title = null;
            this.description = null;
            this.selectedStyle = null;
            this.$emit("addBoard");
          }
        })
        .catch(() => {
          this.is_loading = false;
          this.makeToast(
            "danger",
            "g.show_course/boards/board_added_failed",
            "g.error"
          );
        });
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
