<template>
  <div class="description-component">
    <h3>
      {{ $t("showCourse.resources.descriptionHead") }}
      <button @click="openModal({ isEdit: false, data: {} })">
        <img :src="addIcon" alt="add-icon" />
      </button>
    </h3>
    <div v-for="descriptionItem in descriptionData" class="description-row" :key="descriptionItem.id">
      <p v-html="descriptionItem.description"></p>
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none d-flex align-items-start pt-0 "
        no-caret
        :dropleft="rtl"
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />
        </template>
        <b-dropdown-item
          @click="
            openModal({
              isEdit: true,
              data: {
                id: descriptionItem.id,
                description: descriptionItem.description,
              },
            })
          "
        >
          <feather-icon icon="EditIcon" class="mr-50" />
          <span>{{ $t("g.edit") }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteDescription(descriptionItem.id)">
          <feather-icon icon="Trash2Icon" class="mr-50" />
          <span>{{ $t("g.delete") }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <description-modal
      :data="modalData"
      @update-description="$emit('update-data')"
      :is-edit="modalEdit"
    />
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import DescriptionModal from "@/components/classrooms/resourcesComponent/Modals/DescriptionModal/index.vue";
export default {
  name: "DescriptionComponent",
  props: {
    descriptionData: {
      type: Array,
      required: true,
    },
  },
  components: { BDropdown, BDropdownItem, DescriptionModal },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      modalEdit: false,
      modalData: {},
    };
  },
  methods: {
    async deleteDescription(_id) {
      try {
        const request = await this.$http.delete(`teacher/resources/${_id}`);
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    openModal({ isEdit, data: { id, description } }) {
      if (isEdit) {
        this.modalData = { id, description };
      }
      this.modalEdit = isEdit;
      this.$bvModal.show("description-control-modal");
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
