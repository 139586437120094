<template>
  <section class="show-course-lessons">
    <div class="course-lessons">
      <div class="lessons-title">
        <h4 class="title">{{ $t("g.classroom.lessons") }}</h4>
      </div>

      <AddLessonModal @refresh-data="refreshData" />

      <div class="lessons-container">
        <card-lessons :course_id="course_id" v-for="(lesson, index) in lessons" :key="lesson.id" :lesson="lesson"
          :index="index" />
      </div>
    </div>
  </section>
</template>

<script>
import CardLessons from "./layout/cardLessons.vue";
import AddLessonModal from "@/components/classrooms/boards/showCourses/addLessonModal/index.vue";

export default {
  name: "lessons",
  components: {
    AddLessonModal,
    CardLessons,
  },

  props: ["lessons", "course_id"],
  methods: {
    refreshData() {
      this.$emit('get-courses')
    }
  }
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
