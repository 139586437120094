<template>
  <b-form-group
    :label="$t('g.classroom.show_course/boards/styles')"
    label-for="styles"
    class="mb-2"
    label-size="lg"
  >
    <validation-provider #default="{ errors }" name="style" rules="required">
      <div class="styles-container">
        <div class="styles-content">
          <!-- Default Style -->
          <b-row :class="{ 'd-none': is_collapse_visible }">
            <b-col cols="12" class="available-styles">
              <span
                v-model="selectedStyle"
                class="styles-color-cube"
                @click="selectStyle(null)"
                autocomplete="false"
                :style="{
                  backgroundColor: '#F5F5F5',
                  backgroundImage: `url(${noStyleIcon})`,
                  marginLeft: '8px',
                }"
              />
              <span
                v-for="style in styles"
                :key="style.id"
                :id="`style_${style.id}`"
                autocomplete="false"
                @click="selectStyle(style.id)"
                class="styles-color-cube"
                :style="{
                  backgroundColor: style.primary_color,
                  opacity: selectedStyle !== style.id ? '0.5' : '1',
                  border: '1px solid #2776B6',
                  boxShadow: '0 2px 9px 1px rgba(0, 117, 211, 0.25)',
                }"
              />
            </b-col>
          </b-row>

          <!-- Show All Styles -->
          <b-collapse id="show-all-styles" style="margin-left: 4px">
            <span
              v-model="selectedStyle"
              class="styles-color-cube"
              @click="selectStyle(null)"
              autocomplete="false"
              :style="{
                backgroundColor: '#F5F5F5',
                backgroundImage: `url(${noStyleIcon})`,
                marginLeft: '4px',
              }"
            />
            <span
              v-for="style in styles"
              :key="style.id"
              :id="`style_${style.id}`"
              autocomplete="false"
              @click="selectStyle(style.id)"
              class="styles-color-cube"
              :style="{
                backgroundColor: style.primary_color,
                opacity: selectedStyle !== style.id ? '0.5' : '1',
                border: '1px solid #2776B6',
                boxShadow: '0 2px 9px 1px rgba(0, 117, 211, 0.25)',
              }"
            />
          </b-collapse>

          <!-- Show All Styles Button -->
          <b-row>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button class="show-all-styles-btn" @click="showAllStyles">
                {{
                  is_collapse_visible
                    ? $t("g.classroom.show_course/boards/hide-all-styles")
                    : $t("g.classroom.show_course/boards/show-all-styles")
                }}
                <feather-icon
                  :icon="
                    is_collapse_visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
                  "
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <small class="text-danger">{{
        errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
      }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BButton, BCol, BCollapse, BFormGroup, BRow } from "bootstrap-vue";

export default {
  name: "BoardStyles",
  components: {
    BCollapse,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ValidationProvider,
  },
  data() {
    return {
      styles: [],
      selectedStyle: null,
      is_collapse_visible: false,
      noStyleIcon: require("@/assets/images/icons/no-style-cube.png"),
    };
  },
  methods: {
    showAllStyles() {
      this.$root.$emit("bv::toggle::collapse", "show-all-styles");
      this.is_collapse_visible = !this.is_collapse_visible;
    },
    selectStyle(style) {
      this.selectedStyle = style;
      this.$emit("selectStyle", this.selectedStyle);
    },
    getBoardStyles() {
      this.$http
        .get("/teacher/board-styles")
        .then((response) => {
          this.styles = response.data.data;
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
  },
  created() {
    this.getBoardStyles();
  },
};
</script>

<style src="./_boardStyles.scss" lang="scss" />
