<template>
  <div class="show-course-boards">
    <div class="course-boards">
      <div class="course-boards__header">
        <div class="boards-title">
          <h4 class="title">{{ $t("g.classroom.boards") }}</h4>
        </div>
        <div class="boards-all-link" v-if="boards.length">
          <b-link
            class="boards-link"
            :to="{
              name: 'BoardsList',
              params: { id: course_id },
            }"
          >
            <span>{{ $t("g.classroom.see_all_boards") }}</span>
            <span>
              <feather-icon
                size="16"
                :icon="
                  $store.getters['appConfig/isRTL']
                    ? 'ChevronLeftIcon'
                    : 'ChevronRightIcon'
                "
                color="#135991"
              />
            </span>
          </b-link>
        </div>
      </div>
      <div class="borders">
        <div class="borders__container">
          <div
            class="add-board-card mb-2"
            @click="$bvModal.show('bv-modal-add-board')"
          >
            <div class="content-container">
              <div class="add-circle">
                <img :src="require('@/assets/dev/plus.svg')" alt="" />
              </div>
              <h3>{{ $t("g.show_course/boards/add-board") }}</h3>
            </div>
          </div>

          <AddBoardModal @addBoard="addBoard" />

          <card v-for="board in boards" :board="board" :key="board.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./layout/card.vue";
import { BLink } from "bootstrap-vue";
import AddBoardModal from "@/components/classrooms/boards/showCourses/addBoardModal/index.vue";

export default {
  props: ["boards", "course_id"],
  components: {
    Card,
    BLink,
    AddBoardModal,
  },
  methods: {
    addBoard() {
      this.$emit("addBoard");
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
