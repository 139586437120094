<template>
  <div>
    <loading v-if="courseData.length && notApprovedBoardsLength === 0" />
    <div v-else class="show-course">
      <header-show :course-data="courseData" :current-tap="currentTab" />
      <div class="tabs-wrapper">
        <b-tabs
          content-class="mt-1"
          v-model="currentTab"
          nav-class="nav"
          justified
        >
          <b-tab lazy class="tab">
            <template #title>
              <div class="tab-title">
                <img
                  :src="currentTab !== 0 ? icons.board : icons.ActiveBoard"
                  alt="board-Icon"
                />
                <div>{{ $t("showCourse.tabsHead.board") }}</div>
              </div>
            </template>
            <!-- Approved boards -->
            <boards
              :boards="courseData.boards"
              :course_id="courseData.id"
              :notApprovedBoardsLength="notApprovedBoardsLength"
              :isApproved="true"
              @addBoard="addBoard"
            />
            <!-- End approved boards -->
          </b-tab>

          <b-tab lazy class="tab">
            <template #title>
              <div class="tab-title">
                <img
                  :src="currentTab !== 1 ? icons.lessons : icons.ActiveLesson"
                  alt="board-Icon"
                />
                <div>{{ $t("showCourse.tabsHead.lessons") }}</div>
              </div>
            </template>
            <lessons
              class="pt-2"
              :lessons="courseData.lessons"
              :course_id="courseData.id"
            />
          </b-tab>
          <b-tab lazy class="tab">
            <template #title>
              <div class="tab-title">
                <img
                  :src="
                    currentTab !== 2 ? icons.resources : icons.ActiveResources
                  "
                  alt="board-Icon"
                />
                <div>{{ $t("showCourse.tabsHead.resources") }}</div>
              </div>
            </template>
            <resources-component />
          </b-tab>
          <b-tab lazy class="tab">
            <template #title>
              <div class="tab-title">
                <img
                  :src="
                    currentTab !== 3 ? icons.resources : icons.ActiveResources
                  "
                  alt="board-Icon"
                />
                <div>{{ $t("showCourse.tabsHead.students") }}</div>
              </div>
            </template>
            <students-component />
          </b-tab>
          <b-tab lazy class="tab">
            <template #title>
              <div class="tab-title">
                <img
                  :src="
                    currentTab !== 4 ? icons.students : icons.ActiveStudents
                  "
                  alt="board-Icon"
                />
                <div>{{ $t("showCourse.tabsHead.attendance") }}</div>
              </div>
            </template>
            <attendance-component />
          </b-tab>
        </b-tabs>
      </div>

      <!--      &lt;!&ndash; Lessons &ndash;&gt;-->

      <!-- End lessons -->
    </div>
  </div>
</template>

<script>
import HeaderShow from "@/components/classrooms/boards/showCourses/header/index.vue";
import Boards from "@/components/classrooms/boards/showCourses/boards/index.vue";
import Lessons from "@/components/classrooms/boards/showCourses/lessons/index.vue";
import Loading from "@/components/loading/loading.vue";
import { BTabs, BTab } from "bootstrap-vue";
import StudentsComponent from "@/components/classrooms/StudentsComponent/index.vue";
import ResourcesComponent from "@/components/classrooms/resourcesComponent/index.vue";
import AttendanceComponent from "@/components/classrooms/course/AttendanceComponents/index.vue";
export default {
  name: "ShowCourses",
  components: {
    ResourcesComponent,
    StudentsComponent,
    AttendanceComponent,
    HeaderShow,
    Boards,
    Lessons,
    Loading,
    BTabs,
    BTab,
  },
  data() {
    return {
      isError: false,
      courseData: {},
      notApprovedBoards: [],
      notApprovedBoardsLength: 0,
      courseDataLength: 0,
      currenTabRoute: null,
      tabs: ["boards", "lessons", "resources", "students", "attendance"],
      icons: {
        board: require("@/assets/images/icons/boards 2.svg"),
        lessons: require("@/assets/images/icons/lessons 1.svg"),
        resources: require("@/assets/images/icons/Resources 2.svg"),
        students: require("@/assets/images/icons/Students 1.svg"),
        attendance: require("@/assets/images/icons/Attendance 1.svg"),
        ActiveBoard: require("@/assets/images/icons/active/boards 2.svg"),
        ActiveLesson: require("@/assets/images/icons/active/lessons 1.svg"),
        ActiveResources: require("@/assets/images/icons/active/Resources 2.svg"),
        ActiveStudents: require("@/assets/images/icons/active/State-layer.svg"),
        ActiveAttendance: require("@/assets/images/icons/active/Attendance 1.svg"),
      },
      currentTab: 0,
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSIONS", ["boards", "lessons"]);
      return this.$store.state.userData.sub_Permissions;
    },
  },
  created() {
    this.getCourses();
    this.currentTab = this.tabs.findIndex(
      (ele) => ele === this.$route.query.tab
    );
  },
  methods: {
    addBoard() {
      this.getCourses();
    },
    getCourses() {
      this.$http
        .get(`teacher/courses/${this.$route.params.id}&approved=true`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.courseData = data;
          }
        })
        .catch((err) => {
          this.isError = true;
        });
    },
  },
  watch: {
    notApprovedBoards: {
      handler() {
        this.notApprovedBoardsLength = this.notApprovedBoards.length;
      },
      deep: true,
    },
    "$route.query": {
      deep: true,
      handler(newValue) {
        this.currentTab = this.tabs.findIndex((ele) => newValue.tab === ele);
      },
    },
    currentTab(newValue, oldValue) {
      if (newValue > 0) {
        const { tab } = this.$route.query;
        if (tab !== this.tabs[newValue] && newValue >= 0) {
          this.$router.push({ query: { tab: this.tabs[newValue] } });
        } else {
          // console.log(this.currentTab);
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./_showCourses.scss"></style>
