<template>
  <loading v-if="isLoading" />
  <div v-else class="students-component">
    <b-row class="p-1 students" v-if="students && students.length > 0">
<!--      <b-col cols="1.14">-->
<!--        <student-component-->
<!--          :student="{ id: 0, name: $t('addStudent') }"-->
<!--          :addition="true"-->
<!--        />-->
<!--      </b-col>-->
      <b-col cols="1.14" v-for="student in students">
        <student-component
          :student="student"
          :key="student.id"
          @delete-student="handelSoftDeleteUser"
        />
      </b-col>
    </b-row>
    <b-row v-else class="no-data">
      <p class="m-0">{{$t('noStudentsInThisCourse')}}</p>
    </b-row>
  </div>
</template>
x
<script>
import StudentComponent from "@/components/classrooms/StudentsComponent/StudentComponent/index.vue";
import { BRow, BCol } from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "StudentsComponent",
  components: { Loading, StudentComponent, BRow, BCol },
  beforeMount() {
    this.getCourseStudents();
  },
  data() {
    return {
      students: [],
      isLoading: false,
    };
  },
  methods: {
    async getCourseStudents() {
      try {
        this.isLoading = true;
        const { id } = this.$route.params;
        const response = await this.$http.get(
          `teacher/courses/${id}/students?enrolled=true`
        );
        if ([200, 201].includes(response.status)) {
          const responseData = response.data.data;
          responseData.forEach(({ id, user,enrollments }) => {
            this.students.push({
              id,
              enrollmentId: enrollments[0]?.id,
              studentId: user?.id,
              name: `${user?.first_name} ${user?.last_name}`,
              image: user?.media[0]?.path,
            });
          });
        }
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handelSoftDeleteUser(_id) {
      this.students = this.students.filter((ele) => ele.id !== _id);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/style";
.students {
  gap: 4.35rem;
}
.no-data {
  display: flex;
  justify-content: center;
  color: $primary-color;
  font-weight: 500;
  font-size: 22px;
  margin-top: 25px;
}
</style>
